export const numberWithPeriods = (raw: number): string => {
  if (raw == 0) {
    return "0";
  } else if (!raw) {
    return "N/A";
  }
  return raw.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function round(num: number): string {
  return String(Math.round(num * 100) / 100);
}
