export const backgroundColors = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgb(166, 255, 64, 0.2)",
  "rgb(0, 100, 0, 0.2)",
  "rgb(255, 104, 31, 0.2)",
  "rgb(128, 0, 128, 0.2)",
  "rgb(222, 82, 70, 0.2)",
  "rgb(59, 89, 152, 0.2)",
  "rgb(194, 161, 194, 0.2)",
];

export const borderColors = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgb(166, 255, 64, 1)",
  "rgb(0, 100, 0, 1)",
  "rgb(255, 104, 31, 1)",
  "rgb(128, 0, 128, 1)",
  "rgb(222, 82, 70, 1)",
  "rgb(59, 89, 152, 1)",
  "rgb(194, 161, 194, 1)",
];
