import React, { useEffect, useState } from "react";

import LoadingIcon from "../icons/loading";
import { getCampaignsFromFacebook, FacebookCampaignsPayload } from "../utils/api-facebook";

type TableFacebookProps = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

function TableFacebook({ startDate, endDate }: TableFacebookProps): JSX.Element {
  const [facebookData, setFacebookData] = useState<FacebookCampaignsPayload | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastDatesRequested, setLastDatesRequested] = useState<[Date, Date] | undefined>(undefined);

  const requestFacebookData = async () => {
    if (!startDate || !endDate) return;
    setIsLoading(true);
    const res = await getCampaignsFromFacebook({ startDate, endDate });
    if (res) {
      setFacebookData(res);
      setLastDatesRequested([startDate, endDate]);
      setIsLoading(false);
      return;
    }
    setFacebookData(undefined);
    setLastDatesRequested(undefined);
    setIsLoading(false);
  };

  useEffect(() => {
    requestFacebookData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white shadow rounded-lg p-4  col-span-1 md:col-span-10 xl:col-span-5 h-full">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex justify-between min-w-full">
          <h3 className="text-xl font-bold text-gray-900 mb-2 inline">
            Facebook Campaigns
            {facebookData && (
              <span className="font-normal text-base">
                {" "}
                (from {facebookData.query["start-date"]} to {facebookData.query["end-date"]})
              </span>
            )}
          </h3>
          {isLoading && <LoadingIcon />}
          {!isLoading &&
            !!facebookData &&
            (startDate?.getTime() !== lastDatesRequested?.[0]?.getTime() ||
              endDate?.getTime() !== lastDatesRequested?.[1]?.getTime()) && (
              <button
                className="bg-gray-800 hover:bg-gray-600 text-white font-normal px-5 rounded"
                onClick={requestFacebookData}
              >
                Refresh dates
              </button>
            )}
        </div>
      </div>
      <div>
        {isLoading && !facebookData && <LoadingIcon />}
        <div className="overflow-scroll no-scrollbar max-h-120">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow sm:rounded-lg">
              {facebookData && (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        C1
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        C2
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        C3
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    <tr>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td1</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td2</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td3</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td1</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td2</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td3</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td1</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td2</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td3</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td1</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td2</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td3</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td1</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td2</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td3</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td1</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td2</td>
                      <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-900">td3</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableFacebook;
