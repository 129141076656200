import React, { useEffect, useMemo, useState } from "react";

import DayPickerInput from "react-day-picker/DayPickerInput";

import useAuthenticationStore from "../store/authenticationDashboard";

import BoxesInfo from "~/src/components/BoxesInfo";
import ChartPie from "~/src/components/ChartPie";
import Layout from "~/src/components/Layout";
import SEO from "~/src/components/SEO";
import TableEvents from "~/src/components/TableEvents";
import TableFacebook from "~/src/components/TableFacebook";
import {
  resBySourceMockup,
  resByCampaignMockup,
  resByServiceMockup,
  resLatestAppointmentsMockup,
} from "~/src/mockup/data";
import { AppointmentEvent, UTMGroupedByPayload, UTMMetric, ServicesStatusCount } from "~/src/types";
import namespaced from "~/src/utils/debug";

import "react-day-picker/lib/style.css";

const debug = namespaced("pages/index");
const REFRESH_APPOINTMENTS_INTERVAL = 15000; // ms

function IndexPage(): JSX.Element {
  const { isLoggedIn } = useAuthenticationStore();
  const today = useMemo<Date>(() => new Date(), []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>(
    new Date(new Date().setDate(today.getDate() - 30)),
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>(today);
  const [resCountBySource, setResCountBySource] = useState<UTMGroupedByPayload["data"] | undefined>(undefined);
  const [resCountByCampaign, setResCountByCampaign] = useState<UTMGroupedByPayload["data"] | undefined>(undefined);
  const [resCountByService, setResCountByService] = useState<ServicesStatusCount | undefined>(undefined);
  const [latestAppointmentEvents, setLatestAppointmentEvents] = useState<AppointmentEvent[]>([]);
  const [gotAppointmentsAt, setGotAppointmentsAt] = useState<Date | undefined>(undefined);
  const [shouldRefreshAppointments, setShouldRefreshAppointments] = useState<boolean>(false);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | undefined>(undefined);
  const [isFetchingEvents, setIsFetchingEvents] = useState<boolean>(false);
  const [scope, setScope] = useState<UTMMetric>("total");

  const handleStartDateChange = (day?: Date) => setSelectedStartDate(day);

  const handleEndDateChange = (day?: Date) => setSelectedEndDate(day);

  const handleSubmit = async () => {
    if (!selectedStartDate || !selectedEndDate) return;
    setIsLoading(true);
    const resBySource = resBySourceMockup;
    if (resBySource) setResCountBySource(resBySource.data);
    const resByCampaign = resByCampaignMockup;
    if (resByCampaign) setResCountByCampaign(resByCampaign.data);
    const resByService = resByServiceMockup;
    if (resByService) setResCountByService(resByService);
    setIsLoading(false);
  };

  const updateLatestAppointmentEvents = async () => {
    debug(`(updateLatestAppointmentEvents) shouldRefreshAppointments is ${shouldRefreshAppointments}`);
    setIsFetchingEvents(true);
    try {
      const resLatestAppointments = resLatestAppointmentsMockup;
      if (resLatestAppointments) {
        debug(`will set latest appointment events (${resLatestAppointments.results.length})`);
        setLatestAppointmentEvents(resLatestAppointments.results);
        setGotAppointmentsAt(new Date());
        const theTimeoutID = setTimeout(updateLatestAppointmentEvents, REFRESH_APPOINTMENTS_INTERVAL);
        debug(`Timeout ID is ${theTimeoutID}`);
        setTimeoutID(theTimeoutID);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Failed at updating appointments: ${err}`);
    }
    setIsFetchingEvents(false);
  };

  useEffect(() => {
    if (isLoggedIn) handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      updateLatestAppointmentEvents();
    } else {
      try {
        if (timeoutID) {
          clearTimeout(timeoutID);
          setTimeoutID(undefined);
          debug("success clearing refresh appointments timeout");
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`Failed at clearing refresh appointments timeout: ${err}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      <SEO title="Examedi Marketing Dashboard" />
      <Layout>
        <div className="min-h-screen w-full bg-gray-50 relative overflow-y-auto p-4">
          <div className="w-full grid grid-cols-4 xl:grid-cols-7 2xl:grid-cols-10 gap-4">
            <div className="col-span-full">
              <div className="bg-white shadow rounded-lg p-4">
                <div className="sm:inline">
                  <span>From</span>
                  <div className="border-2 border-black-400 p-2 my-3 mx-3 inline rounded-lg">
                    <DayPickerInput
                      onDayChange={handleStartDateChange}
                      keepFocus={false}
                      value={selectedStartDate}
                      dayPickerProps={{
                        modifiers: selectedStartDate ? { disabled: { before: new Date("2021-09-17") } } : {},
                      }}
                    />
                  </div>
                </div>
                <div className="mt-6 sm:inline sm:mt-0">
                  <span>To</span>
                  <div className="border-2 border-black-400 p-2 my-3 mx-3 inline rounded-lg ml-8 sm:ml-3">
                    <DayPickerInput
                      onDayChange={handleEndDateChange}
                      keepFocus={false}
                      dayPickerProps={{
                        modifiers: selectedStartDate ? { disabled: { before: selectedStartDate } } : {},
                      }}
                      value={selectedEndDate}
                    />
                  </div>
                </div>
                <div className="mt-6 md:inline md:mt-0">
                  {!isLoading && (
                    <button
                      className="bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-7 rounded"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  )}
                  {isLoading && (
                    <svg
                      fill="none"
                      className="w-8 h-8 mx-7 mt-2 md:mt-0 animate-spin inline"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                        fill="currentColor"
                        fillRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-full grid grid-cols-12 xl:grid-cols-1 xl:col-span-1 2xl:col-span-1 gap-4">
              <BoxesInfo data={resCountBySource} setScope={setScope} scope={scope} />
            </div>
            <div className="col-span-full md:col-span-2 2xl:col-span-3">
              <ChartPie data={resCountBySource} metric={scope} group="Sources" invertColors />
            </div>
            <div className="col-span-full md:col-span-2 2xl:col-span-3">
              <ChartPie data={resCountByCampaign} metric={scope} group="Campaigns" />
            </div>
            <div className="col-span-full md:col-span-2 2xl:col-span-3">
              <ChartPie data={resCountByService} metric={scope} group="Products" invertColors isProduct />
            </div>
            <div className="col-span-full 2xl:col-span-5">
              <TableEvents
                data={latestAppointmentEvents}
                isFetchingEvents={isFetchingEvents}
                gotAppointmentsAt={gotAppointmentsAt}
                shouldRefreshAppointments={shouldRefreshAppointments}
                setShouldRefreshAppointments={setShouldRefreshAppointments}
              />
            </div>
            <div className="col-span-full 2xl:col-span-5">
              <TableFacebook startDate={selectedStartDate} endDate={selectedEndDate} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default IndexPage;
