import React, { Dispatch, SetStateAction } from "react";

import { UTMGroupedByPayload, UTMMetric } from "~/src/types";
import { numberWithPeriods, round } from "~/src/utils/number";

type BoxInfoProps = {
  amount: number;
  percentage?: string;
  description: string;
  onClick: () => void;
  selected: boolean;
};
type BoxesInfoProps = {
  data: UTMGroupedByPayload["data"];
  setScope: Dispatch<SetStateAction<UTMMetric>>;
  scope: UTMMetric;
};

function BoxInfo({ amount, percentage, description, onClick, selected }: BoxInfoProps) {
  return (
    <div
      className={
        selected
          ? "bg-blue-200 border-2 border-blue-300 shadow cursor-pointer rounded-lg p-4 col-span-full sm:col-span-4 md:col-span-3"
          : "bg-white shadow cursor-pointer rounded-lg p-4 col-span-full sm:col-span-4 md:col-span-3 xl:grid-cols-1"
      }
      onClick={onClick}
    >
      <div className="">
        <span className="text-xl md:text-2xl leading-none font-bold text-gray-900">{numberWithPeriods(amount)}</span>
        <span className="block font-bold text-gray-700 text-base">{description}</span>
      </div>
      {percentage && <div className="w-0 text-gray-700 text-base font-bold mt-5">{percentage}%</div>}
    </div>
  );
}

function BoxesInfo({ data, setScope, scope }: BoxesInfoProps): JSX.Element {
  const entries = data ? Object.entries(data) : [];
  const visits = data ? entries.reduce((acc, i) => acc + i[1]["visits"], 0) : 0;
  const appointments = data ? entries.reduce((acc, i) => acc + i[1]["scheduled"], 0) : 0;
  const conversions = data ? entries.reduce((acc, i) => acc + i[1]["converted"], 0) : 0;

  return (
    <>
      <BoxInfo selected={scope === "visits"} amount={visits} description="Visits" onClick={() => setScope("visits")} />
      <BoxInfo
        selected={scope === "scheduled"}
        amount={appointments}
        percentage={round((100 * appointments) / (visits || 1))}
        description="Schedules"
        onClick={() => setScope("scheduled")}
      />
      <BoxInfo
        selected={scope === "converted"}
        amount={conversions}
        percentage={round((100 * conversions) / (visits || 1))}
        description="Conversions"
        onClick={() => setScope("converted")}
      />
    </>
  );
}
export default BoxesInfo;
