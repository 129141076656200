/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";

import { DateRangeInput } from "~/src/types";
import { parseDate } from "~/src/utils/date";
import namespaced from "~/src/utils/debug";

const debug = namespaced("api-facebook");

export type FacebookCampaignsPayload = any;

const client = axios.create({ baseURL: "https://api.facebook.com/" });

const access_token =
  "EAAGRvL8uIA8BAFANxnrhYn1uZBZAwEPTHqblAZCAMj2ueWvE2v2sx5YazGNbXynQ2I3PJgKBMAeLZAPcMQ5ljS7MHXcOZCjBUfLqUyb84Kr9O4bdPIBmLOa3ivFZAZBMeScuTZA6KNXO2sqrc5skC9pWHZCwcnc3nvrsHCB9DfTCe7nGKZBJ9R2SkAIYbqqagojB0ZD";
const ad_account_id = "act_2076172205866748";
const app_secret = "4c0b174a813c481eb944535759dcdef6";
const app_id = "441714824060943";

export async function getCampaignsFromFacebook({
  startDate,
  endDate,
}: DateRangeInput): Promise<FacebookCampaignsPayload | 401 | undefined> {
  try {
    const fields = ["spend", "impressions", "campaign_name"];
    const params = {
      time_range: { since: parseDate(startDate), until: parseDate(endDate) },
      filtering: [{ field: "impressions", operator: "GREATER_THAN", value: "0" }],
      level: "ad",
      breakdowns: [],
    };
    // const res = client.get(`someurl?${qs.stringify({ fields, params })}`);
    const res = { query: { "start-date": "2021-12-12", "end-date": "2021-12-14" } };
    debug(`Faceboook response was: ${res}`);
    return res;
  } catch (err: any) {
    if (err.response?.status && err.response.status === 401) return 401;
    debug(err);
  }
}
