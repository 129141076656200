import create, { SetState } from "zustand";

import namespaced from "~/src/utils/debug";

const debug = namespaced("context/authentication");

type AuthenticationState = {
  token?: string;
  isLoggedIn: boolean;
  setToken: (value?: string) => void;
  setIsLoggedIn: (value: boolean) => void;
};

const useAuthenticationGoogleStore = create(
  (set: SetState<AuthenticationState>): AuthenticationState => ({
    token: undefined,
    isLoggedIn: false,
    setToken: (token?: string) => {
      set({ token });
      if (!token) {
        localStorage.removeItem("google-access-token");
      }
      debug("token state changed\n", token);
    },
    setIsLoggedIn: (isLoggedIn: boolean) => {
      set({ isLoggedIn });
      debug("isLoggedIn state changed\n", isLoggedIn);
    },
  }),
);

export default useAuthenticationGoogleStore;
