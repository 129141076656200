import React, { useMemo, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import LoadingIcon from "../icons/loading";

import { UTMGroupedByPayload, UTMMetric } from "~/src/types";
import { backgroundColors, borderColors } from "~/src/utils/colors";
// import namespaced from "~/src/utils/debug";

// const debug = namespaced("ChartPie");

ChartJS.register(ArcElement, Tooltip, Legend);

type ChartPieProps = {
  data: UTMGroupedByPayload["data"];
  metric: UTMMetric;
  group: string;
  invertColors?: boolean | undefined;
  expandable?: boolean | undefined;
  groupable?: boolean | undefined;
  from?: string | undefined;
  until?: string | undefined;
  isLoading: boolean;
};

function parseLabel(label: string, shorten: boolean) {
  return shorten ? label.slice(0, 15) : label;
}

function ChartPie({
  data,
  metric,
  group,
  invertColors,
  expandable,
  groupable,
  from,
  until,
  isLoading,
}: ChartPieProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isGrouped, setIsGrouped] = useState<boolean>(false);
  const [parsedData, setParsedData] = useState<{
    labels: string[];
    datasets: [
      {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor: string[];
        borderWidth: number;
      },
    ];
  }>({ labels: [], datasets: [{ label: "", data: [], backgroundColor: [], borderColor: [], borderWidth: 1 }] });

  useMemo(() => {
    if (isGrouped) {
      const rawEntries = data ? Object.entries(data) : [];
      const groupedEntries: Record<string, Record<UTMMetric, number>> = {
        "Sangre/Orina": {
          initialized: 0,
          address_set: 0,
          date_set: 0,
          scheduled: 0,
          visits: 0,
          converted: 0,
        },
      };
      for (const rawEntry of rawEntries) {
        if (rawEntry[0].toLowerCase().includes("pcr")) {
          if (groupedEntries[rawEntry[0]]) {
            groupedEntries[rawEntry[0]]["initialized"] += rawEntry[1]["initialized"];
            groupedEntries[rawEntry[0]]["address_set"] += rawEntry[1]["address_set"];
            groupedEntries[rawEntry[0]]["date_set"] += rawEntry[1]["date_set"];
            groupedEntries[rawEntry[0]]["scheduled"] += rawEntry[1]["scheduled"];
            groupedEntries[rawEntry[0]]["visits"] += rawEntry[1]["visits"];
            groupedEntries[rawEntry[0]]["converted"] += rawEntry[1]["converted"];
          } else {
            groupedEntries[rawEntry[0]] = {
              initialized: rawEntry[1]["initialized"] || 0,
              address_set: rawEntry[1]["address_set"] || 0,
              date_set: rawEntry[1]["date_set"] || 0,
              scheduled: rawEntry[1]["scheduled"] || 0,
              visits: rawEntry[1]["visits"] || 0,
              converted: rawEntry[1]["converted"] || 0,
            };
          }
        } else {
          groupedEntries["Sangre/Orina"]["initialized"] += rawEntry[1]["initialized"] || 0;
          groupedEntries["Sangre/Orina"]["address_set"] += rawEntry[1]["address_set"] || 0;
          groupedEntries["Sangre/Orina"]["date_set"] += rawEntry[1]["date_set"] || 0;
          groupedEntries["Sangre/Orina"]["scheduled"] += rawEntry[1]["scheduled"] || 0;
          groupedEntries["Sangre/Orina"]["visits"] += rawEntry[1]["visits"] || 0;
          groupedEntries["Sangre/Orina"]["converted"] += rawEntry[1]["converted"] || 0;
        }
      }
      const entries = Object.entries(groupedEntries);
      const entryLabels = data ? entries.map((i) => parseLabel(i[0], isExpanded)) : [];
      const requestedEntryMetrics = data ? entries.map((i) => i[1][metric]) : [];
      setParsedData({
        labels: entryLabels,
        datasets: [
          {
            label: `# of ${metric}`,
            data: requestedEntryMetrics,
            backgroundColor: invertColors ? backgroundColors.slice().reverse() : backgroundColors,
            borderColor: invertColors ? borderColors.slice().reverse() : borderColors,
            borderWidth: 1,
          },
        ],
      });
    } else {
      const entries = data ? Object.entries(data) : [];
      const entryLabels = data ? entries.map((i) => parseLabel(i[0], isExpanded)) : [];
      const requestedEntryMetrics = data ? entries.map((i) => i[1][metric]) : [];
      setParsedData({
        labels: entryLabels,
        datasets: [
          {
            label: `# of ${metric}`,
            data: requestedEntryMetrics,
            backgroundColor: invertColors ? backgroundColors.slice().reverse() : backgroundColors,
            borderColor: invertColors ? borderColors.slice().reverse() : borderColors,
            borderWidth: 1,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isGrouped, isExpanded, metric]);

  return (
    <div className="bg-white shadow rounded-lg py-4 h-full">
      <div className="mb-4 flex items-center justify-between w-full px-4">
        <div>
          <h3 className="text-xl font-bold text-gray-900 mb-2 inline">{group}</h3>
          <span className="font-normal text-sm">
            {" "}
            ({group === "Products" && metric == "visits" ? "initialized" : metric}
            {from && until ? ` from ${from} to ${until}` : ""})
          </span>
        </div>
        {isLoading && <LoadingIcon />}
        <div>
          {!isLoading && expandable && !isGrouped && (
            <div
              className={isExpanded ? "text-blue-400 text-sm cursor-pointer" : "cursor-pointer text-gray-600 text-sm"}
              onClick={() => setIsExpanded((prevState) => !prevState)}
            >
              expand
            </div>
          )}
          {!isLoading && groupable && (
            <div
              className={isGrouped ? "text-blue-400 text-sm cursor-pointer" : "cursor-pointer text-gray-600 text-sm"}
              onClick={() => setIsGrouped((prevState) => !prevState)}
            >
              group
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between mb-4 xl:pt-4">
        {isExpanded && (
          <Pie
            data={parsedData}
            options={{
              aspectRatio: 0.5,
              layout: { padding: { left: expandable ? 20 : 10, right: expandable ? 20 : 10 } },
            }}
          />
        )}
        {!isExpanded && (
          <Pie
            data={parsedData}
            options={{
              aspectRatio: 1,
              layout: { padding: { left: expandable ? 20 : 10, right: expandable ? 20 : 10 } },
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ChartPie;
