export const resByCampaignMockup = {
  msg: "Data retrieved successfully",
  data: {
    b2b: { total: 380, appointed: 78, converted: 2 },
    edificios: { total: 5, appointed: 0, converted: 0 },
    examenesmedicos: { total: 3440, appointed: 641, converted: 69 },
    pcr: { total: 8379, appointed: 1651, converted: 100 },
    presentacion: { total: 3, appointed: 0, converted: 0 },
    reembolso: { total: 1092, appointed: 199, converted: 12 },
    vacunavalidacion: { total: 1040, appointed: 165, converted: 11 },
    null: { total: 5404, appointed: 1118, converted: 234 },
  },
  start: "2021-11-01",
  end: "2021-11-06",
};

export const resBySourceMockup = {
  msg: "Data retrieved successfully",
  data: {
    google: { total: 14332, appointed: 2734, converted: 194 },
    organic: { total: 5403, appointed: 1118, converted: 234 },
    tarjetas: { total: 8, appointed: 0, converted: 0 },
  },
  start: "2021-11-01",
  end: "2021-11-06",
};

export const resByServiceMockup = {
  "PCR Nasal c/ Domicilio Gratis": { total: 17, appointed: 17, converted: 0 },
  "PCR Express (Resultados en 4-6 Horas)": { total: 6, appointed: 6, converted: 0 },
  "PCR Salival": { total: 1, appointed: 1, converted: 0 },
  "Examen Anticuerpos IgG": { total: 1, appointed: 1, converted: 0 },
  "Examen Anticuerpos IgM": { total: 1, appointed: 1, converted: 0 },
};

export const resLatestAppointmentsMockup = {
  count: 155,
  next: "https://api.examedi.com/dashboard/appointments/get_latest_appointments/?days=1&page=2&page_size=100",
  previous: null,
  results: [
    {
      id: "f92994e0-eaa2-4789-a49b-af16f3f3f225",
      created_at: "2021-12-14T13:51:08.462653Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-15T12:00:00Z",
      status: "confirmed",
      patient: { fullname: "Ramos", rut: "608.160-2", email: "camilarocio.rm@gmail.com" },
      extra_patients: [],
    },
    {
      id: "2f17b668-81c7-4525-8a16-35a31b841b6b",
      created_at: "2021-12-14T13:49:46.500732Z",
      service_names: "PCR Express (Resultados en 4-6 Horas), PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T15:00:00Z",
      status: "scheduled",
      patient: { fullname: "González", rut: "383.681-7", email: "agonbust30@gmail.com" },
      extra_patients: [],
    },
    {
      id: "f3589972-49a7-42f8-b819-bb99f9e227e6",
      created_at: "2021-12-14T13:48:18.362967Z",
      service_names: "PCR Express (Resultados en 4-6 Horas), PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-16T12:45:00Z",
      status: "confirmed",
      patient: { fullname: "Alessandri Alves de Oliveira", rut: "388.767-9", email: "renato.aao@gmail.com" },
      extra_patients: [],
    },
    {
      id: "6c6a9996-439d-42a4-85c2-8b6bf1c953af",
      created_at: "2021-12-14T13:40:48.123650Z",
      service_names: "PCR Express (Resultados en 4-6 Horas), PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T14:15:00Z",
      status: "confirmed",
      patient: { fullname: "Alejandro Doren Vial", rut: "432.557-2", email: "alejandro.doren@gmail.com" },
      extra_patients: [],
    },
    {
      id: "84634233-d1d2-45a8-a1fb-952d6aa72280",
      created_at: "2021-12-14T13:36:06.017708Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-15T12:00:00Z",
      status: "confirmed",
      patient: { fullname: "Paz Aguayo Herrera", rut: "153.723-8", email: "cpaguayo@uc.cl" },
      extra_patients: [],
    },
    {
      id: "d21e0d51-01ed-48f0-9b83-fc0137343ed4",
      created_at: "2021-12-14T13:31:02.012599Z",
      service_names: "PCR Express (Resultados en 4-6 Horas), PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T13:30:00Z",
      status: "scheduled",
      patient: { fullname: "Alejandro Doren Vial", rut: "432.557-2", email: "alejandro.doren@gmail.com" },
      extra_patients: [],
    },
    {
      id: "7157f02c-2094-4044-a454-b671b7ef590f",
      created_at: "2021-12-14T13:28:46.226443Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-18T11:15:00Z",
      status: "confirmed",
      patient: { fullname: "Anibañ Cantero Cajas", rut: "686.339-3", email: "rinosurgery@gmail.com" },
      extra_patients: [],
    },
    {
      id: "e4a6cf79-9279-4af8-b127-ae97f36f7136",
      created_at: "2021-12-14T13:17:46.216706Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T12:00:00Z",
      status: "confirmed",
      patient: { fullname: "ADOLFO FUENTES  ESCALA", rut: "306.857-7", email: "carolinaescala@yahoo.com" },
      extra_patients: [],
    },
    {
      id: "43c0ce46-6cc4-46cc-8a0f-4241a205ccc9",
      created_at: "2021-12-14T13:14:40.371323Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-15T12:00:00Z",
      status: "scheduled",
      patient: { fullname: "Kinderman", rut: "625.464-0", email: "lilikinderman@gmail.com" },
      extra_patients: [],
    },
    {
      id: "512f61c3-2b9e-46e8-b379-cfda1fd286f6",
      created_at: "2021-12-14T13:08:44.653905Z",
      service_names: "PCR Salival",
      begin_date: "2021-12-17T21:00:00Z",
      status: "confirmed",
      patient: { fullname: "Carrillo", rut: "027.283-5", email: "rocio.rivera+11@vtex.com.br" },
      extra_patients: [],
    },
    {
      id: "492fe913-6c7d-4c63-acf8-7e9bdd86c967",
      created_at: "2021-12-14T13:04:44.046198Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-14T12:00:00Z",
      status: "scheduled",
      patient: { fullname: "IVONNE REVECO DIAZ", rut: "026.223-6", email: "tomasrreve+1@gmail.com" },
      extra_patients: [],
    },
    {
      id: "a24f19a8-ce8e-4da9-ac65-0e3e64198fcb",
      created_at: "2021-12-14T12:58:40.197090Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-15T12:00:00Z",
      status: "confirmed",
      patient: { fullname: "Dana Wassermann Taylor", rut: "432.129-9", email: "catawasser@gmail.com" },
      extra_patients: [],
    },
    {
      id: "56881b7d-9d61-49c8-9411-9de51146d246",
      created_at: "2021-12-14T12:24:21.927609Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T17:15:00Z",
      status: "confirmed",
      patient: { fullname: "Jorquera Giglio", rut: "128.702-4", email: "conijgiglio@gmail.com" },
      extra_patients: [],
    },
    {
      id: "a75f37a4-b4d7-4626-8666-2447ee4b9d9f",
      created_at: "2021-12-14T12:24:03.494967Z",
      service_names: "PCR Nasal, PCR Nasal",
      begin_date: "2021-12-18T12:00:00Z",
      status: "confirmed",
      patient: { fullname: "lambertini", rut: "643.736-4", email: "luli_lambertini@hotmail.com" },
      extra_patients: [],
    },
    {
      id: "5e4628de-7c28-4584-af81-5a86384a242a",
      created_at: "2021-12-14T12:23:46.152423Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-14T14:15:00Z",
      status: "initialized",
      patient: { fullname: "Carmona Reyes", rut: "026.112-7", email: "alvaro.carmonar@gmail.com" },
      extra_patients: [],
    },
    {
      id: "f145ec6c-5178-4ad7-b3fa-e4dbb7b76159",
      created_at: "2021-12-14T12:10:16.553166Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-15T11:15:00Z",
      status: "confirmed",
      patient: { fullname: "Sateler", rut: "431.402-0", email: "satelerd@gmail.com" },
      extra_patients: [],
    },
    {
      id: "82ca3f28-c80c-402e-aa41-c77d8dadc9e1",
      created_at: "2021-12-14T11:57:29.812606Z",
      service_names: "PCR Salival",
      begin_date: "2021-12-16T21:00:00Z",
      status: "confirmed",
      patient: {
        fullname: "JAVIER RUIZ PUSCHEL",
        rut: "124.636-9",
        email: "fernandojavierruizp@gmail.com",
      },
      extra_patients: [],
    },
    {
      id: "3e6d70de-5d53-4508-8b6a-b8f5259bbbec",
      created_at: "2021-12-14T11:49:42.199706Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T23:15:00Z",
      status: "confirmed",
      patient: { fullname: "Ossa Dietsch", rut: "644.322-1", email: "sossa@minrel.gob.cl" },
      extra_patients: [],
    },
    {
      id: "f2a723cc-fb7d-44e6-b421-c7547521d1a5",
      created_at: "2021-12-14T11:47:48.034441Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-16T17:15:00Z",
      status: "scheduled",
      patient: { fullname: "Agustina De Marco", rut: "747.826-2", email: "demarco.agustina@gmail.com" },
      extra_patients: [],
    },
    {
      id: "1813090e-5165-47a4-8e50-9f76cb2828fc",
      created_at: "2021-12-14T11:39:36.910477Z",
      service_names: "PCR Express (Resultados en 4-6 Horas), PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-18T12:00:00Z",
      status: "confirmed",
      patient: { fullname: "Vasquez", rut: "528.453-5", email: "elitavas@gmail.com" },
      extra_patients: [],
    },
    {
      id: "56fbc348-19ca-4908-908a-1917edc4ad13",
      created_at: "2021-12-14T11:33:44.911581Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T13:30:00Z",
      status: "confirmed",
      patient: { fullname: "Herrera", rut: "002.030-4", email: "sofi.elena.h@gmail.com" },
      extra_patients: [],
    },
    {
      id: "d57a8d91-69ce-46ae-8216-c3856ee97541",
      created_at: "2021-12-14T11:32:07.434401Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-15T12:00:00Z",
      status: "confirmed",
      patient: { fullname: "Ossa Dietsch", rut: "644.322-1", email: "sossa@minrel.gob.cl" },
      extra_patients: [],
    },
    {
      id: "9b99693f-f596-4520-aeaa-9b44a15ac044",
      created_at: "2021-12-14T11:26:16.174624Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T12:45:00Z",
      status: "scheduled",
      patient: { fullname: "Palma", rut: "300.187-6", email: "lauraignaciapm@gmail.com" },
      extra_patients: [],
    },
    {
      id: "6b70f0e5-a498-493b-bd81-6b19460f92fd",
      created_at: "2021-12-14T11:24:24.735674Z",
      service_names: "PCR Nasal, PCR Nasal",
      begin_date: "2021-12-15T10:30:00Z",
      status: "confirmed",
      patient: { fullname: "Figoli", rut: "851.328-2", email: "ceciliafigoli@gmail.com" },
      extra_patients: [],
    },
    {
      id: "57846ab9-974d-4cbb-9909-3ce80a5dd293",
      created_at: "2021-12-14T10:41:25.414368Z",
      service_names: "PCR Nasal",
      begin_date: "2021-12-17T16:30:00Z",
      status: "confirmed",
      patient: { fullname: "Tatiana Alas Martínez", rut: "233.501-K", email: "jammyalas3b@gmail.com" },
      extra_patients: [],
    },
    {
      id: "54f4e90d-9c4b-43c2-9379-fb9870c3c844",
      created_at: "2021-12-14T03:31:12.348275Z",
      service_names:
        "Hemograma, Perfil Lipídico, Perfil Hepático, Glucosa en sangre, Insulina, Creatinina en Sangre, Vitamina D",
      begin_date: "2021-12-15T11:15:00Z",
      status: "initialized",
      patient: { fullname: "Inés Vargas Ugarte", rut: "089.898-2", email: "macavargasu@hotmail.com" },
      extra_patients: [],
    },
    {
      id: "14d06b8b-1eee-42ca-9478-a89ea812753d",
      created_at: "2021-12-14T03:14:17.801403Z",
      service_names:
        "PCR Express (Resultados en 4-6 Horas), PCR Express (Resultados en 4-6 Horas), PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-16T14:15:00Z",
      status: "confirmed",
      patient: { fullname: "ELIANA TRINCADO SAAVEDRA", rut: "062.651-7", email: "PAMELA.TRINCADO@GMAIL.COM" },
      extra_patients: [],
    },
    {
      id: "19926601-8617-41db-add0-64099471ccdf",
      created_at: "2021-12-14T03:01:28.420836Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T09:45:00Z",
      status: "confirmed",
      patient: { fullname: "Aparcana", rut: "218.088-4", email: "renzo.aparcana88@gmail.com" },
      extra_patients: [],
    },
    {
      id: "38adbe70-12ff-4bb2-891b-d63f9b5ffb9f",
      created_at: "2021-12-14T02:58:07.580724Z",
      service_names: "PCR Express (Resultados en 4-6 Horas)",
      begin_date: "2021-12-15T09:45:00Z",
      status: "scheduled",
      patient: { fullname: "Aparcana", rut: "218.088-4", email: "renzo.aparcana88@gmail.com" },
      extra_patients: [],
    },
  ],
};
